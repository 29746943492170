import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MultiQuestionComponent } from './pages/multi-question/multi-question.component';
import { SingleQuestionComponent } from './pages/single-question/single-question.component';
import { AskPredefinedQuestionComponent } from './pages/ask-predefined-question/ask-predefined-question.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { PredictedQuestionsComponent } from './pages/predicted-questions/predicted-questions.component';
import { GeneratedKeywordsComponent } from './pages/generated-keywords/generated-keywords.component';
import { ConfigComponent } from './pages/config/config.component';
import { ArticleComponent } from './pages/article/article.component';
import { ArticlesComponent } from './pages/articles/articles.component';
import { TermsComponent } from './pages/terms/terms.component';
import { QuestionsComponent } from './pages/questions/questions.component';
import { QuestionComponent } from './pages/question/question.component';
import { DocumentsComponent } from './pages/documents/documents.component';

import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    MultiQuestionComponent,
    SingleQuestionComponent,
    SignInComponent,
    PredictedQuestionsComponent,
    AskPredefinedQuestionComponent,
    GeneratedKeywordsComponent,
    ConfigComponent,
    ArticleComponent,
    ArticlesComponent,
    TermsComponent,
    QuestionsComponent,
    QuestionComponent,
    DocumentsComponent,
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions(getApp(), 'europe-central2')),
    provideStorage(() => getStorage()),
    HttpClientModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { OpenAIConfig, OpenAIPrompt } from './config.component.models';

export const OPEN_AI_CONFIG: OpenAIConfig = {
  temperature: 0.9,
  top_p: 1,
  frequency_penalty: 0.0,
  presence_penalty: 0.6,
};

export const PROMPT_CONFIG_DEFAULT: OpenAIPrompt[] = [
  {
    role: 'system',
    content: 'You are a helpful assistant that finds information in the context.',
    order: 1,
  },
  {
    role: 'user',
    content: 'context: ${{context}}',
    order: 2,
  },
  {
    role: 'user',
    content: 'Answer the question below as truthfully as you can based on the context.',
    order: 3,
  },
  {
    role: 'user',
    content: 'Question: ${{question}}',
    order: 4,
  },
];

export const GPT_PROMPT_CONFIG_DEFAULT: OpenAIPrompt[] = [
  {
    role: 'system',
    content: 'You are a helpful medical assistant.',
    order: 1,
  },
  {
    role: 'user',
    content: 'Answer the question below as truthfully as you can.',
    order: 2,
  },
  {
    role: 'user',
    content: 'Question: ${{question}}',
    order: 3,
  },
];

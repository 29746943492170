export interface OpenAIConfig {
  temperature: number;
  top_p: number;
  frequency_penalty: number;
  presence_penalty: number;
}

export interface OpenAIPrompt {
  role: string;
  content: string;
  order: number;
}

export interface FindInDocumentsRequestPayload {
  message: string;
}

export enum GenerativeQaScope {
  Articles = 'articles',
  Documents = 'documents',
  Llm = 'llm',
}

export interface GenerativeQaPayload {
  message: string;
  scope: GenerativeQaScope;
  questionId: string;
};

export interface FindInDocumentsSuccessResponse {
  fileName: string;
  message: string;
  score: number | string;
}

export interface FindInDocumentsErrorResponse {
  error: string;
  score: number | string | null;
}

export interface FindInArticlesSuccessResponse {
  articleIds: string[];
  message: string;
  score: number | string;
}

export type FindInArticlesResponse = FindInArticlesSuccessResponse | FindInDocumentsErrorResponse | null;
export type FindInDocumentsResponse = FindInDocumentsSuccessResponse | FindInDocumentsErrorResponse | null;
export type FindInLlmResponse = FindInDocumentsSuccessResponse | FindInDocumentsErrorResponse | null;

export type GenerativeQaResults = FindInArticlesResponse | FindInDocumentsResponse | FindInLlmResponse;

export interface ProcessGptQuestionSuccessResult {
  message: string;
}

export interface ProcessGptQuestionErrorResult {
  error: string;
}

export type ProcessGptQuestionResponse = ProcessGptQuestionSuccessResult | ProcessGptQuestionErrorResult;

export interface FindInDocumentsRequestResponse {
  filesResults: (FindInDocumentsResponse | null)[];
  articlesResults: (FindInArticlesResponse | null)[];
  gptResults?: (ProcessGptQuestionResponse | null)[];
  extractedQuestions: string[] | null;
  questionId: string | null;
}

export interface GenerativeQaResponse {
  scope: GenerativeQaScope;
  results:  GenerativeQaResults[];
  extractedQuestions: string[] | null;
  questionId: string | null;
};

export interface GenerativeQaHelperResponse {
  questionId: string | null;
};

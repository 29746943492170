<div
  class="content-container"
>
  <div class="content-row row">
    <div class="title">
      Files ({{totalCount$ | async}})
    </div>

    <form
      [formGroup]="searchForm"
      class="row form"
    >
      <mat-form-field>
        <input
          matInput
          formControlName="query"
          placeholder="Filter by..."
        />

      </mat-form-field>
    </form>

    <div class="file-upload-container">
      <input type="file" class="file-input"
          (change)="onFileSelected($event)" #fileUpload multiple>

      <div class="file-upload">
        <button mat-mini-fab color="primary" class="icon"
          (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="uploadErrorMessage" class="content-row file-upload-error">
    {{uploadErrorMessage}}
  </div>

  <ng-container *ngIf="documentsStorageService.isFetching$ | async">
    <div class="overlay-container">
      <mat-spinner
        [diameter]="35"
      ></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="(files$ | async) as files">

    <ng-container *ngIf="!files.length">
      <div class="content-row">
        <div class="row file-row">
          <span>
            No results
          </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let file of files">
      <div class="content-row">
        <div class="row file-row">
          <span>
            {{file.name}}
          </span>

          <div class="row">
            <button
              mat-icon-button
              (click)="deleteDocument(file)"
              [disabled]="filesDeleteInProgress[file.id]"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

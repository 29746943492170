import { Component, inject, OnInit } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Firestore, collection, getDocs } from '@angular/fire/firestore';
import { StorageReference } from 'firebase/storage';
import { QuerySnapshot } from 'firebase/firestore';

import { FilesService } from 'app/shared/services/files/files.service';

import {
  PredictedQuestion,
  QuestionByDocument,
  DeleteQuestionsRequestPayload,
} from './predicted-questions.component.models';

@Component({
  selector: 'app-predicted-questions',
  templateUrl: './predicted-questions.component.html',
  styleUrls: ['./predicted-questions.component.scss']
})
export class PredictedQuestionsComponent implements OnInit{
  private functions: Functions = inject(Functions);

  private firestore: Firestore = inject(Firestore);

  public questionsByDocuments$: Observable<QuestionByDocument[]>;
  public files$: Observable<StorageReference[]>;
  public filesFilteredList$: Observable<StorageReference[]>;

  public currentDeletingFileId: string | null;

  public currentGeneratingFileFullPath: string | null;

  constructor(
    private filesService: FilesService,
  ) {}

  public ngOnInit(): void {
    this.setQuestionsByDocuments();
    this.setFilesList();
  }

  private setFilesList(): void {
    this.files$ = this.filesService.files$;

    this.filesFilteredList$ = this.files$.pipe(
      mergeMap((files) => {
        return this.questionsByDocuments$.pipe(
          map((questionsByDocuments) => {
            return files.filter((file) => {
              const isFileExists = Boolean(questionsByDocuments.find(({ fileName }) => fileName === file.name));
              return !isFileExists;
            });
          })
        );
      })
    );
  }

  private setQuestionsByDocuments(): void {
    const collectionRef = collection(this.firestore, 'predefined-questions');

    this.questionsByDocuments$ = from(getDocs(collectionRef)).pipe(
      map((data: QuerySnapshot<PredictedQuestion>) => {
        return data.docs.reduce<QuestionByDocument[]>((result, questionSnapshot) => {
          const question = questionSnapshot.data();
          const questionId = questionSnapshot.id;
          const { answer: { fileMetadata: { id, name } } } = question;

          const existingCollectionIndex = result.findIndex(({ fileId }) => fileId === id);

          if (existingCollectionIndex === -1) {
            result.push({
              fileId: id,
              fileName: name,
              questions: [question],
              questionsIds: [questionId],
            });
          } else {
            const collectionElement = result[existingCollectionIndex];

            collectionElement.questions.push(question);
            collectionElement.questionsIds.push(questionId);
          }

          return result;
        }, []);
      }),
    );

  }

  public async handleQuestionsDelete(question: QuestionByDocument): Promise<void> {
    const findInDocumentsFunction = httpsCallable<
      DeleteQuestionsRequestPayload,
      void
      >(this.functions, 'deletePredefinedQuestions');

    const payload = {
      file: {
        id: question.fileId,
        name: question.fileName,
      },
      ids: question.questionsIds,
    };

    this.currentDeletingFileId = question.fileId;

    await findInDocumentsFunction(payload);

    this.currentDeletingFileId = null;

    this.setQuestionsByDocuments();
    this.setFilesList();
  }
}

import { Component, ViewChild, ElementRef, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, NgZone } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Feedback, FeedbackSource } from 'models/question';

import { AuthService } from 'app/shared/services/auth/auth.service';
import { User } from 'app/shared/services/auth/auth.service.models';

import {
  FindInDocumentsRequestPayload,
  FindInDocumentsRequestResponse,
} from './question-form.component.models';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss']
})
export class QuestionFormComponent implements OnInit, OnChanges {
  @ViewChild('resultContainer', { static: false }) public resultContainer: ElementRef<HTMLDivElement>;

  @Input() public isFetching = false;
  @Input() public response: FindInDocumentsRequestResponse | null = null;
  @Input() public title: string;
  @Input() public feedbackValue: Record<FeedbackSource, boolean | null>;
  @Input() public initialMessage: string | null;

  @Output() public onSubmit = new EventEmitter<FindInDocumentsRequestPayload>();

  @Output() public onFeedback = new EventEmitter<Feedback>();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public messageForm: UntypedFormGroup | null = null;

  public feedbackSource = FeedbackSource;

  public $user: Observable<User>;

  constructor(
    private route: ActivatedRoute,
    private _ngZone: NgZone,
    private authService: AuthService,
  ) {}

  public ngOnInit(): void {
    this.messageForm = new UntypedFormGroup({
      message: new UntypedFormControl(this.initialMessage || this.route.snapshot.queryParams?.message || '', [Validators.required]),
    });

    this.$user = this.authService.$user;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isFetching?.previousValue && !changes.isFetching.currentValue) {
      setTimeout(() => {
        this.resultContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  public triggerResize(): void {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  public handleMessageSubmit(): void {
    const { message } = this.messageForm.value;
    const payload = {
      message,
    };

    this.onSubmit.emit(payload);
  }

  public handleFeedbackClick(questionId: string, isPositive: boolean, source: FeedbackSource): void {
    const payload = {
      isPositive,
      source,
    };

    this.onFeedback.emit(payload);
  }
}

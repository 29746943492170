import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable, from } from 'rxjs';
import { StorageReference } from 'firebase/storage';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent {
  @Input() public files$: Observable<StorageReference[]>;
  @Input() public activeActionFileFullPath: string | null;

  @Output() public onFileAction = new EventEmitter<StorageReference>();

  constructor() {}

  public handleFileClick(file: StorageReference) {
    this.onFileAction.emit(file);
  }
}

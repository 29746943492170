import { Injectable, inject } from '@angular/core';
import { Auth, idToken, signInWithEmailAndPassword, user } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from './auth.service.models';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth);
  private functions: Functions = inject(Functions);

  private idToken$ = idToken(this.auth);

  private $firebaseUser = user(this.auth);

  public $userSubject: BehaviorSubject<User | undefined> = new BehaviorSubject(null);

  public get $uid(): Observable<string> {
    return this.$firebaseUser.pipe(
      map((user) => user.uid),
    );
  }

  public get $user(): Observable<User> {
    return this.$userSubject.asObservable();
  }

  public get $isAuthorized(): Observable<boolean> {
    return this.idToken$.pipe(
      map((token): boolean => (
        token !== null && !jwtHelper.isTokenExpired(token)
      )),
    );
  }

  constructor() {}

  public async fetchUser() {
    const getUser = httpsCallable<
      null,
      User
      >(this.functions, 'getUser');

    const { data } = await getUser(null);

    this.$userSubject.next(data);
  }

  public signIn(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }
}

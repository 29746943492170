<form
  class="content-container"
  [formGroup]="messageForm"
  (ngSubmit)="handleMessageSubmit()"
>
  <div class="row row-start content-row">
    <mat-form-field>
      <input
        matInput
        formControlName="message"
        placeholder="Find in predefined questions"
      />
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!messageForm.valid"
    >
      Submit
    </button>
  </div>

  <div class="row flex-start content-row">
    <ng-container *ngIf="isFetching">
      <mat-spinner
        [diameter]="22"
      ></mat-spinner>

      <div>
        Analyzing research data
      </div>
    </ng-container>

    <ng-container *ngIf="!isFetching && response">
      <mat-icon
        fontIcon="check_circle"
        class="icon-blue"
      ></mat-icon>

      <div>
        Finished analyzing
      </div>
    </ng-container>
  </div>
</form>

<div
  *ngIf="response"
  class="content-container"
>
  <ng-container *ngIf="response.results">
    <div class="content-row title">
      Result
    </div>

    <ng-container *ngFor="let result of response.results">
      <div class="content-row">
        <ng-container *ngIf="result.error">
          <div class="answer-row">
            {{result.error}}
          </div>

          <div>
            <span class="text-bold">
              Score:
            </span>
            {{result.score || 'None'}}
          </div>
        </ng-container>

        <ng-container *ngIf="!result.error">
          <div class="answer-row">
            <span class="text-bold">
              Predefined question:
            </span>

            {{result.question}}
          </div>

          <div class="answer-row">
            <span class="text-bold">
              Answer:
            </span>

            {{result.answer.message}}
          </div>

          <ng-container *ngIf="result.answer.fileName">
            <div class="">
              <span class="text-bold">
                File:
              </span>
  
              {{result.answer.fileName}}
            </div>
          </ng-container>

          <ng-container *ngIf="!result.answer.fileName">
            <div class="row row-start articles-row">
              <span class="text-bold">
                Article:
              </span>

                <a
                  [routerLink]="['/articles', result.answer.fileMetadata.id]"
                  class="article-link"
                  title="Go to article"
                >
                  {{result.answer.fileMetadata.name}}
                </a>
            </div>
          </ng-container>

          <div class="">
            <span class="text-bold">
              Score:
            </span>

            {{result.answer.score}}
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="content-row">
      <div class="answer-row">
        <span class="text-bold">
          Didn't find correct answer ?
        </span>

        <div class="buttons-container">
          <a
            routerLink="/"
            class="nav-link"
            [queryParams]="{message: messageForm.value.message}"
          >
            Go to question flow
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

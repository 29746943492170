export enum Routes {
  Home = '',
  SignIn = 'sign-in',
  SingleQuestion = 'single-question',
  MultiQuestion = 'multi-question',
  PredictedQuestions = 'predicted-questions',
  GeneratedKeywords = 'generated-keywords',
  AskPredefinedQuestion = 'ask-predefined-question',
  Articles = 'articles',
  Terms = 'terms',
  AdminConfig = 'config',
  Questions = 'questions',
  Documents = 'documents',
  Any = '**',
}

<form
  [formGroup]="messageForm"
  (ngSubmit)="handleMessageSubmit()"
  class="content-container"
>
  <div class="row row-start content-row">
    <mat-form-field>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        formControlName="message"
        [placeholder]="title"></textarea>
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!messageForm.valid || isFetching"
    >
      Submit
    </button>
  </div>

  <div class="row flex-start content-row">
    <ng-container *ngIf="isFetching">
      <mat-spinner
        [diameter]="22"
      ></mat-spinner>

      <div>
        Analyzing research data
      </div>
    </ng-container>

    <ng-container *ngIf="!isFetching && response">
      <mat-icon
        fontIcon="check_circle"
        class="icon-blue"
      ></mat-icon>

      <div>
        Finished analyzing
      </div>
    </ng-container>
  </div>
</form>

<div
  class="content-container"
  #resultContainer
>
  <ng-container *ngIf="response">
    <ng-container *ngIf="response.filesResults">
      <div class="content-row feedback-buttons-row row">
        <span class="title">Basel search result</span>
  
        <button
          mat-mini-fab
          color="primary"
          title="Dislike answer"
          type="button"
          (click)="handleFeedbackClick(response.questionId, false, feedbackSource.Files)"
          [ngClass]="{'feedback-button-inactive': feedbackValue[feedbackSource.Files]}"
        >
          <mat-icon
            fontIcon="thumb_down"
            class="icon"
          ></mat-icon>
        </button>
  
        <button
          mat-mini-fab
          color="primary"
          title="Like answer"
          type="button"
          (click)="handleFeedbackClick(response.questionId, true, feedbackSource.Files)"
          [ngClass]="{'feedback-button-inactive': feedbackValue[feedbackSource.Files] === false}"
        >
          <mat-icon
            fontIcon="thumb_up"
            class="icon"
          ></mat-icon>
        </button>
      </div>
  
      <ng-container *ngFor="let answer of response.filesResults; let i = index">
        <ng-container *ngIf="answer">
          <div class="content-row">
            <div class="answer-row">
              <span class="text-bold">
                Question:
              </span>
  
              {{response.extractedQuestions[i]}}
            </div>
  
            <ng-container *ngIf="answer.error">
              <div class="answer-row">
                {{answer.error}}
              </div>
            </ng-container>
  
            <ng-container *ngIf="!answer.error">
              <div class="answer-row">
                <span class="text-bold">
                  Answer:
                </span>
  
                {{answer.message}}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="response.articlesResults">
      <div class="content-row feedback-buttons-row row">
        <span class="title">PubMed search result</span>
  
        <button
          mat-mini-fab
          color="primary"
          title="Dislike answer"
          type="button"
          (click)="handleFeedbackClick(response.questionId, false, feedbackSource.Articles)"
          [ngClass]="{'feedback-button-inactive': feedbackValue[feedbackSource.Articles]}"
        >
          <mat-icon
            fontIcon="thumb_down"
            class="icon"
          ></mat-icon>
        </button>
  
        <button
          mat-mini-fab
          color="primary"
          title="Like answer"
          type="button"
          (click)="handleFeedbackClick(response.questionId, true, feedbackSource.Articles)"
          [ngClass]="{'feedback-button-inactive': feedbackValue[feedbackSource.Articles] === false}"
        >
          <mat-icon
            fontIcon="thumb_up"
            class="icon"
          ></mat-icon>
        </button>
      </div>
  
      <ng-container *ngFor="let answer of response.articlesResults; let i = index">
        <ng-container *ngIf="answer">
          <div class="content-row">
            <div class="answer-row">
              <span class="text-bold">
                Question:
              </span>
  
              {{response.extractedQuestions[i]}}
            </div>
  
            <ng-container *ngIf="answer.error">
              <div class="answer-row">
                {{answer.error}}
              </div>
  
              <div class="">
                <span class="text-bold">
                  Score:
                </span>
                {{answer.score || 'None'}}
              </div>
            </ng-container>
  
            <ng-container *ngIf="!answer.error">
              <div class="answer-row">
                <span class="text-bold">
                  Answer:
                </span>
  
                {{answer.message}}
              </div>
  
              <div class="row articles-row">
                <span class="text-bold">
                  Articles:
                </span>
  
                <ng-container *ngFor="let articleId of answer.articleIds; index as i">
                  <a
                    [routerLink]="['/articles', articleId]"
                    class="article-link"
                    title="Go to article"
                  >
                    {{answer.articleTitles[i] || articleId}}
                  </a>,
                </ng-container>
              </div>
  
              <div class="">
                <span class="text-bold">
                  Score:
                </span>
  
                {{answer.score}}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="response.gptResults">
      <ng-container
        *ngIf="($user | async) as user"
      >
        <ng-container
          *ngIf="user && user.isAdmin"
        >
          <div class="content-row feedback-buttons-row row">
            <span class="title">ChatGPT search result</span>
          </div>
      
          <ng-container *ngFor="let answer of response.gptResults; let i = index">
            <ng-container *ngIf="answer">
              <div class="content-row">
                <div class="answer-row">
                  <span class="text-bold">
                    Question:
                  </span>
      
                  {{response.extractedQuestions[i]}}
                </div>
  
                <ng-container *ngIf="answer.error">
                  <div class="answer-row">
                    {{answer.error}}
                  </div>
                </ng-container>
      
                <ng-container *ngIf="!answer.error">
                  <div class="answer-row">
                    <span class="text-bold">
                      Answer:
                    </span>
      
                    {{answer.message}}
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="content-row">
    <div>
      <span class="text-bold">
        This medical research app,
      </span>
    </div>

    <div>
      is designed to answer your questions in this specialized medical field using information from accessible articles.
    </div>
  </div>

  <div class="content-row">
    <div>
      <span class="text-bold">
        The app's goal is to analyze your questions
      </span>
    </div>

    <div>
      to develop a decision support system based on the users and patients needs. The answers provided are based on diverse knowledge pools to test how AI can be trained for maximum specificity and personalization.
    </div>
  </div>

  <div class="content-row">
    <div>
      <span class="text-bold">
        To ensure privacy, 
      </span>
    </div>

    <div>
      please avoid adding sensitive information to your question. We are saving questions and  answers, the users feedback based on thumbs up or down, but no user information.
    </div>
  </div>

  <!-- <ng-container *ngIf="response.extractedQuestions">
    <div class="content-row">
      <div class="">
        <span class="text-bold">
          Extracted questions:
        </span>

        <ng-container *ngFor="let question of response.extractedQuestions">
          <br/>{{question}}
        </ng-container>
      </div>
    </div>
  </ng-container> -->
</div>

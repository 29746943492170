import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Term, BaseTerm } from 'app/pages/terms/terms.models';

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  private headers: HttpHeaders;
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      Authorization: '2c0ceb0c70740d39fc294243fc427ca7ea47efbf',
      'Content-Type': 'application/json',
    });

    this.baseUrl = 'https://europe-central2-buildingcloud-ai.cloudfunctions.net/articlesGateway/terms';
  }

  public fetchTerms() {
    return this.http.get<Term[]>(this.baseUrl, {
      headers: this.headers,
    });
  }

  public addTerm(payload: BaseTerm) {
    return this.http.post<Term>(this.baseUrl, payload,{
      headers: this.headers,
    });
  }

  public deleteTerm(id: number) {
    return this.http.delete(`${this.baseUrl}/${id}`,{
      headers: this.headers,
    });
  }
}

<div
  class="content-container"
>
  <div class="title content-row">Single question config</div>

  <div>
    <div class="row content-row">
      <div class="subtitle">Request config</div>

      <div class="buttons-container">
        <button
          mat-button
          color="warn"
          (click)="resetSingleQuestionConfig()"
          type="button"
        >
          Reset
        </button>

        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="handleSingleQuestionConfigSubmit()"
          [disabled]="!singleQuestionForm.valid"
        >
          Save
        </button>
      </div>
    </div>

    <div class="content-row">
      <div #singleQuestionConfigJson></div>
    </div>

    <div class="row content-row">
      <div class="subtitle">Prompt</div>

      <div class="buttons-container">
        <button
          mat-button
          color="warn"
          (click)="resetSingleQuestionPrompt()"
          type="button"
        >
          Reset
        </button>

        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="handleSingleQuestionPromptSubmit()"
          [disabled]="!singleQuestionForm.valid"
        >
          Save
        </button>
      </div>
    </div>

    <div class="content-row">
      <div #singleQuestionPromptJson></div>
    </div>

    <ng-container *ngIf="isSingleQuestionConfigFetching">
      <div class="overlay-container">
        <mat-spinner
          [diameter]="35"
        ></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="content-container"
>
  <div class="title content-row">Multi question config</div>

  <div>
    <div class="row content-row">
      <div class="subtitle">Request config</div>

      <div class="buttons-container">
        <button
          mat-button
          color="warn"
          (click)="resetMultiQuestionConfig()"
          type="button"
        >
          Reset
        </button>

        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="handleMultiQuestionConfigSubmit()"
          [disabled]="!multiQuestionForm.valid"
        >
          Save
        </button>
      </div>
    </div>

    <div class="content-row">
      <div #multiQuestionConfigJson></div>
    </div>

    <div class="row content-row">
      <div class="subtitle">Prompt</div>

      <div class="buttons-container">
        <button
          mat-button
          color="warn"
          (click)="resetMultiQuestionPrompt()"
          type="button"
        >
          Reset
        </button>

        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="handleMultiQuestionPromptSubmit()"
          [disabled]="!multiQuestionForm.valid"
        >
          Save
        </button>
      </div>
    </div>

    <div class="content-row">
      <div #multiQuestionPromptJson></div>
    </div>

    <ng-container *ngIf="isMultiQuestionConfigFetching">
      <div class="overlay-container">
        <mat-spinner
          [diameter]="35"
        ></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="content-container"
>
  <div class="title content-row">Gpt question config</div>

  <div>
    <div class="row content-row">
      <div class="subtitle">Request config</div>

      <div class="buttons-container">
        <button
          mat-button
          color="warn"
          (click)="resetGptQuestionConfig()"
          type="button"
        >
          Reset
        </button>

        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="handleGptQuestionConfigSubmit()"
          [disabled]="!gptQuestionForm.valid"
        >
          Save
        </button>
      </div>
    </div>

    <div class="content-row">
      <div #gptQuestionConfigJson></div>
    </div>

    <div class="row content-row">
      <div class="subtitle">Prompt</div>

      <div class="buttons-container">
        <button
          mat-button
          color="warn"
          (click)="resetGptQuestionPrompt()"
          type="button"
        >
          Reset
        </button>

        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="handleGptQuestionPromptSubmit()"
          [disabled]="!gptQuestionForm.valid"
        >
          Save
        </button>
      </div>
    </div>

    <div class="content-row">
      <div #gptQuestionPromptJson></div>
    </div>

    <ng-container *ngIf="isGptQuestionConfigFetching">
      <div class="overlay-container">
        <mat-spinner
          [diameter]="35"
        ></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../services/auth/auth.service';
import { Routes } from 'app/app-routing.models';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.$user.pipe(
      map((user) => {
        if (user && user.isAdmin) {
          return true;
        }

        this.router.navigate([Routes.Home]);

        return false;
      }),
    );
  }
}

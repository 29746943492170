<div
  class="content-container"
>
  <ng-container
    *ngIf="question"
  >

  <div class="content-row row-with-gap row">
    <div class="title">{{question.text}}</div>

    <div class="row-with-gap row">
      <span class="text-bold">
        PubMed
      </span>

      <mat-icon
        fontIcon="thumb_up"
        class="icon"
      ></mat-icon>

      <span>{{question.totalPositiveFeedbackByArticles}}</span>
    </div>

    <div class="row-with-gap row">
      <mat-icon
        fontIcon="thumb_down"
        class="icon"
      ></mat-icon>

      <span>{{question.totalNegativeFeedbackByArticles}}</span>
    </div>

    <div class="row-with-gap row">
      <span class="text-bold">
        Basel
      </span>

      <mat-icon
        fontIcon="thumb_up"
        class="icon"
      ></mat-icon>

      <span>{{question.totalPositiveFeedbackByFiles}}</span>
    </div>

    <div class="row-with-gap row">
      <mat-icon
        fontIcon="thumb_down"
        class="icon"
      ></mat-icon>

      <span>{{question.totalNegativeFeedbackByFiles}}</span>
    </div>
  </div>

  <div class="content-row row row-with-gap">
    <div class="subtitle">
      Date: {{question.dateCreated}}
    </div>

    <div class="subtitle">
      Type: {{question.typeString}}
    </div>
  </div>

  <ng-container *ngIf="question.responses?.documents">
    <div class="content-row title">
      Basel search result
    </div>

    <ng-container *ngFor="let answer of question.responses.documents.results; let i = index">
      <ng-container *ngIf="answer">
        <div class="content-row">
          <div class="answer-row">
            <span class="text-bold">
              Question:
            </span>

            {{question.responses.documents.extractedQuestions[i]}}
          </div>

          <ng-container *ngIf="answer.error">
            <div class="answer-row">
              {{answer.error}}
            </div>
          </ng-container>

          <ng-container *ngIf="!answer.error">
            <div class="answer-row">
              <span class="text-bold">
                Answer:
              </span>

              {{answer.message}}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="question.responses?.articles">
    <div class="content-row title">
      PubMed search result
    </div>

    <ng-container *ngFor="let answer of question.responses.articles.results; let i = index">
      <ng-container *ngIf="answer">
        <div class="content-row">
          <div class="answer-row">
            <span class="text-bold">
              Question:
            </span>

            {{question.responses.articles.extractedQuestions[i]}}
          </div>

          <ng-container *ngIf="answer.error">
            <div class="answer-row">
              {{answer.error}}
            </div>

            <div class="">
              <span class="text-bold">
                Score:
              </span>
              {{answer.score || 'None'}}
            </div>
          </ng-container>

          <ng-container *ngIf="!answer.error">
            <div class="answer-row">
              <span class="text-bold">
                Answer:
              </span>

              {{answer.message}}
            </div>

            <div class="row articles-row">
              <span class="text-bold">
                Articles:
              </span>

              <ng-container *ngFor="let articleId of answer.articleIds; index as i">
                <a
                  [routerLink]="['/articles', articleId]"
                  class="article-link"
                  title="Go to article"
                >
                  {{answer.articleTitles[i] || articleId}}
                </a>,
              </ng-container>
            </div>

            <div class="">
              <span class="text-bold">
                Score:
              </span>

              {{answer.score}}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="question.responses?.llm">
    <div class="content-row title">
      ChatGPT search result
    </div>

    <ng-container *ngFor="let answer of question.responses.llm.results; let i = index">
      <ng-container *ngIf="answer">
        <div class="content-row">
          <div class="answer-row">
            <span class="text-bold">
              Question:
            </span>

            {{question.responses.llm.extractedQuestions[i]}}
          </div>

          <ng-container *ngIf="answer.error">
            <div class="answer-row">
              {{answer.error}}
            </div>
          </ng-container>

          <ng-container *ngIf="!answer.error">
            <div class="answer-row">
              <span class="text-bold">
                Answer:
              </span>

              {{answer.message}}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

    <!-- <ng-container *ngIf="question.response.filesResults">
      <div class="content-row title">
        Files search result
      </div>

      <ng-container *ngFor="let answer of question.response.filesResults">
        <ng-container *ngIf="answer">
          <div class="content-row">
            <ng-container *ngIf="answer.error">
              <div class="answer-row">
                {{answer.error}}
              </div>

              <div class="">
                <span class="text-bold">
                  Score:
                </span>
                {{answer.score || 'None'}}
              </div>
            </ng-container>

            <ng-container *ngIf="!answer.error">
              <div class="answer-row">
                <span class="text-bold">
                  Answer:
                </span>

                {{answer.message}}
              </div>

              <div class="">
                <span class="text-bold">
                  File:
                </span>

                {{answer.fileName}}
              </div>

              <div class="">
                <span class="text-bold">
                  Score:
                </span>

                {{answer.score}}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="question.response.articlesResults">
      <div class="content-row title">
        Articles search result
      </div>
  
      <ng-container *ngFor="let answer of question.response.articlesResults">
        <ng-container *ngIf="answer">
          <div class="content-row">
            <ng-container *ngIf="answer.error">
              <div class="answer-row">
                {{answer.error}}
              </div>
  
              <div class="">
                <span class="text-bold">
                  Score:
                </span>
                {{answer.score || 'None'}}
              </div>
            </ng-container>
  
            <ng-container *ngIf="!answer.error">
              <div class="answer-row">
                <span class="text-bold">
                  Answer:
                </span>
  
                {{answer.message}}
              </div>
  
              <div class="row articles-row">
                <span class="text-bold">
                  Articles:
                </span>
  
                <ng-container *ngFor="let articleId of answer.articleIds; index as i">
                  <a
                    [routerLink]="['/articles', articleId]"
                    class="article-link"
                    title="Go to article"
                  >
                    {{answer.articleTitles[i] || articleId}}
                  </a>,
                </ng-container>
              </div>
  
              <div class="">
                <span class="text-bold">
                  Score:
                </span>
  
                {{answer.score}}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="question.response.gptResults">
      <div class="content-row title">
        ChatGPT search result
      </div>
  
      <ng-container *ngFor="let answer of question.response.gptResults">
        <ng-container *ngIf="answer">
          <div class="content-row">
            <ng-container *ngIf="answer.error">
              <div class="answer-row">
                {{answer.error}}
              </div>
            </ng-container>
  
            <ng-container *ngIf="!answer.error">
              <div class="answer-row">
                <span class="text-bold">
                  Answer:
                </span>
  
                {{answer.message}}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container> -->
  </ng-container>
</div>

import { inject, Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageReference } from 'firebase/storage';
import { Storage, ref, listAll } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private fireStorage: Storage = inject(Storage);
  private fireStorageRef: StorageReference;

  public files$: Observable<StorageReference[]>;
  public pagginatedFiles$: Observable<StorageReference[]>;

  private pageSubj$ = new BehaviorSubject<number>(1);
  private querySubj$ = new BehaviorSubject<string | null>(null);

  private maxResults = 50;

  constructor() {
    this.fireStorageRef = ref(this.fireStorage, '/');

    this.files$ = from(listAll(this.fireStorageRef)).pipe(
      map((storageList) => storageList.items),
    );

    this.pagginatedFiles$ = combineLatest([this.files$, this.pageSubj$, this.querySubj$]).pipe(
      map(([files, page, query]) => {
        const end = page * this.maxResults
        
        let filteredFiles = files;

        if (query) {
          filteredFiles = filteredFiles.filter((file) => file.name.toLowerCase().includes(query.toLowerCase()));
        }

        if (end >= filteredFiles.length) {
          return filteredFiles;
        }

        return filteredFiles.slice(0, end);
      }),
    );
  }

  public loadNextPage(): void {
    this.pageSubj$.next(this.pageSubj$.value + 1);
  }

  public setSearchQuery(query: string | null): void {
    this.pageSubj$.next(1);
    this.querySubj$.next(query);
  }
}

export type { StorageReference };
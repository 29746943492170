import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SavedMultiQuestionResponse, SavedSingleQuestionResponse } from './last-asked-questions.service.models';

@Injectable({
  providedIn: 'root',
})
export class LastAskedQuestionsService {
  private singleQuestionResponseSubject$: BehaviorSubject<SavedSingleQuestionResponse | null> = new BehaviorSubject(null);
  private multiQuestionResponseSubject$: BehaviorSubject<SavedMultiQuestionResponse | null> = new BehaviorSubject(null);

  public get singleQuestionResponse$(){
    return this.singleQuestionResponseSubject$.asObservable();
  }

  public get multiQuestionResponse$() {
    return this.multiQuestionResponseSubject$.asObservable();
  }

  constructor() {}

  public setSingleQuestionResponse (response: SavedSingleQuestionResponse | null): void {
    this.singleQuestionResponseSubject$.next(response);
  }

  public setMultiQuestionResponse (response: SavedMultiQuestionResponse | null): void {
    this.multiQuestionResponseSubject$.next(response);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Routes } from 'app/app-routing.models';
import { AuthService } from 'app/shared/services/auth/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public authForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  public ngOnInit() {}

  public async handleAuthSubmit(): Promise<void> {
    const { password, email } = this.authForm.value;

    try {
      await this.authService.signIn(email, password);
      await this.router.navigate([Routes.Home]);
    } catch (error) {
      this.authForm.controls.password.setErrors({ incorrect: error.message });
    }
  }
}

import { Component, inject, OnInit } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { take } from 'rxjs/operators';

import { Feedback, FeedbackSource } from 'models/question'

import { QuestionsFeedbackService } from 'app/shared/services/questions-feedback/questions-feedback.service';
import { LastAskedQuestionsService } from 'app/shared/services/last-asked-questions/last-asked-questions.service';

import {
  FindInDocumentsRequestPayload,
  FindInDocumentsRequestResponse,
  GenerativeQaPayload,
  GenerativeQaScope,
  FindInArticlesResponse,
  FindInDocumentsResponse,
  GenerativeQaResponse,
  FindInLlmResponse,
  GenerativeQaHelperResponse,
} from './multi-question.component.models';

@Component({
  selector: 'app-multi-question',
  templateUrl: './multi-question.component.html',
  styleUrls: ['./multi-question.component.scss']
})
export class MultiQuestionComponent implements OnInit {
  private functions: Functions = inject(Functions);

  public feedbackValue: Record<FeedbackSource, boolean | null> = {
    [FeedbackSource.Files]: null,
    [FeedbackSource.Articles]: null,
  };

  public isFetching = false;

  public response: FindInDocumentsRequestResponse | null = null;

  constructor(
    private questionsFeedbackService: QuestionsFeedbackService,
    public lastAskedQuestionsService: LastAskedQuestionsService,
  ) {}

  ngOnInit(): void {
    this.lastAskedQuestionsService.multiQuestionResponse$
    .pipe(
      take(1),
    )
    .subscribe((data) => {
      this.response = data?.response || null;
    });
  }

  public async handleMessageSubmit(payload: FindInDocumentsRequestPayload): Promise<void> {
    this.isFetching = true;
    this.response = null;
    this.lastAskedQuestionsService.setMultiQuestionResponse(null);

    const generativeQaFunction = httpsCallable<
    GenerativeQaPayload,
    GenerativeQaResponse
    >(this.functions, 'generativeQa', { timeout: 540000 });

    const generativeQaHelperFunction = httpsCallable<
    unknown,
    GenerativeQaHelperResponse
    >(this.functions, 'generativeQaHelper');

    const { data: { questionId } } = await generativeQaHelperFunction({});

    if (!questionId) {
      return;
    }

    Object.values(GenerativeQaScope).forEach((scope) => {
      generativeQaFunction({
        scope,
        questionId,
        ...payload,
      }).then(({ data: response }) => {
        if (scope === GenerativeQaScope.Articles) {
          this.response = {
            articlesResults: response.results.filter((result) => result) as FindInArticlesResponse[],
            filesResults: [
              ...(this.response?.filesResults || []),
            ],
            gptResults: [
              ...(this.response?.gptResults || []),
            ],
            ...response,
          };
        } else if (scope === GenerativeQaScope.Llm) {
          this.response = {
            gptResults: response.results.filter((result) => result) as FindInLlmResponse[],
            filesResults: [
              ...(this.response?.filesResults || []),
            ],
            articlesResults: [
              ...(this.response?.articlesResults || []),
            ],
            ...response,
          };
        } else if (scope === GenerativeQaScope.Documents) {
          this.response = {
            filesResults: response.results.filter((result) => result) as FindInDocumentsResponse[],
            articlesResults: [
              ...(this.response?.articlesResults || []),
            ],
            gptResults: [
              ...(this.response?.gptResults || []),
            ],
            ...response,
          };
        }

        if (this.response.filesResults?.length && this.response.articlesResults?.length && this.response.gptResults?.length) {
          this.isFetching = false;
        }

        this.lastAskedQuestionsService.setMultiQuestionResponse({
          message: payload.message,
          response: this.response,
        });
      });
    });
  }

  public async handleFeedbackSubmit(feedback: Feedback): Promise<void> {
    this.feedbackValue[feedback.source] = feedback.isPositive;

    this.questionsFeedbackService.setFeedback(this.response.questionId, feedback);
  }
}

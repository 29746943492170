<div class="content-container">
  <div class="content-row title">
    Keywords
  </div>

  <div
    class="card-content"
  >
    <ng-container *ngIf="keywords$ | async as keywords">
      <mat-accordion class="keywords-accordion">
        <ng-container *ngFor="let keywordsByFile of keywords">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title class="mat-panel-title">
                {{keywordsByFile.file.name}}
              </mat-panel-title>
              <mat-panel-description>
                <button
                  *ngIf="currentDeletingId !== keywordsByFile.id"
                  mat-icon-button
                  (click)="handleKeywordsDelete(keywordsByFile)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>

                <mat-spinner
                  *ngIf="currentDeletingId === keywordsByFile.id"
                  diameter="15"
                ></mat-spinner>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div>
              <div class="answer-content">
                <div class="card-content">
                  <ng-container *ngFor="let keyword of keywordsByFile.keywords">
                    <span class="keyword">{{keyword}}</span>,
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </ng-container>
  </div>
</div>

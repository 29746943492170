import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { Firestore, collection, doc, setDoc, getDocs } from '@angular/fire/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import JSONEditor from 'jsoneditor';
import { QueryDocumentSnapshot, QuerySnapshot } from 'firebase/firestore';

import { OPEN_AI_CONFIG, PROMPT_CONFIG_DEFAULT, GPT_PROMPT_CONFIG_DEFAULT } from './config.component.constants';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit, AfterViewInit {
  private firestore: Firestore = inject(Firestore);

  @ViewChild('singleQuestionConfigJson', { static: false }) public singleQuestionConfigJson: ElementRef;
  @ViewChild('singleQuestionPromptJson', { static: false }) public singleQuestionPromptJson: ElementRef;

  @ViewChild('multiQuestionConfigJson', { static: false }) public multiQuestionConfigJson: ElementRef;
  @ViewChild('multiQuestionPromptJson', { static: false }) public multiQuestionPromptJson: ElementRef;

  @ViewChild('gptQuestionConfigJson', { static: false }) public gptQuestionConfigJson: ElementRef;
  @ViewChild('gptQuestionPromptJson', { static: false }) public gptQuestionPromptJson: ElementRef;

  public singleQuestionForm: UntypedFormGroup | null = null;
  public isSingleQuestionConfigFetching = false;
  private singleQuestionConfigJsonEditor: JSONEditor | null = null;
  private singleQuestionPromptJsonEditor: JSONEditor | null = null;

  public multiQuestionForm: UntypedFormGroup | null = null;
  public isMultiQuestionConfigFetching = false;
  private multiQuestionConfigJsonEditor: JSONEditor | null = null;
  private multiQuestionPromptJsonEditor: JSONEditor | null = null;

  public gptQuestionForm: UntypedFormGroup | null = null;
  public isGptQuestionConfigFetching = false;
  private gptQuestionConfigJsonEditor: JSONEditor | null = null;
  private gptQuestionPromptJsonEditor: JSONEditor | null = null;

  constructor() {}

  public async ngOnInit(): Promise<void> {
    this.singleQuestionForm = new UntypedFormGroup({
      configJson: new UntypedFormControl(OPEN_AI_CONFIG),
      promptJson: new UntypedFormControl(PROMPT_CONFIG_DEFAULT),
    });

    this.multiQuestionForm = new UntypedFormGroup({
      configJson: new UntypedFormControl(OPEN_AI_CONFIG),
      promptJson: new UntypedFormControl(PROMPT_CONFIG_DEFAULT),
    });

    this.gptQuestionForm = new UntypedFormGroup({
      configJson: new UntypedFormControl(OPEN_AI_CONFIG),
      promptJson: new UntypedFormControl(GPT_PROMPT_CONFIG_DEFAULT),
    });

    const singleQuestionConfigsCollection = collection(this.firestore, 'single-question-configs');

    const singleQuestionQuerySnapshot = await getDocs(singleQuestionConfigsCollection);

    const singleQuestionExtractedResult = this.extractConfigFromSnapshot(singleQuestionQuerySnapshot);

    if (singleQuestionExtractedResult.configJson) {
      this.setSingleQuestionConfig(singleQuestionExtractedResult.configJson);
    }

    if (singleQuestionExtractedResult.promptJson) {
      this.setSingleQuestionPrompt(singleQuestionExtractedResult.promptJson);
    }

    const multiQuestionsConfigsCollection = collection(this.firestore, 'multi-question-configs');

    const multiQuestionsQuerySnapshot = await getDocs(multiQuestionsConfigsCollection);

    const multiQuestionExtractedResult = this.extractConfigFromSnapshot(multiQuestionsQuerySnapshot);

    if (multiQuestionExtractedResult.configJson) {
      this.setMultiQuestionConfig(multiQuestionExtractedResult.configJson);
    }

    if (multiQuestionExtractedResult.promptJson) {
      this.setMultiQuestionPrompt(multiQuestionExtractedResult.promptJson);
    }

    const gptQuestionsConfigsCollection = collection(this.firestore, 'gpt-question-configs');

    const gptQuestionsQuerySnapshot = await getDocs(gptQuestionsConfigsCollection);

    const gptQuestionExtractedResult = this.extractConfigFromSnapshot(gptQuestionsQuerySnapshot);

    if (gptQuestionExtractedResult.configJson) {
      this.setGptQuestionConfig(gptQuestionExtractedResult.configJson);
    }

    if (gptQuestionExtractedResult.promptJson) {
      this.setGptQuestionPrompt(gptQuestionExtractedResult.promptJson);
    }
  }

  public ngAfterViewInit(): void {
    const singleQuestionEditors = this.initJsonEditors(
      this.singleQuestionConfigJson,
      this.singleQuestionPromptJson,
      this.singleQuestionForm,
    );

    this.singleQuestionConfigJsonEditor = singleQuestionEditors.configJsonEditor;
    this.singleQuestionPromptJsonEditor = singleQuestionEditors.promptJsonEditor;

    const multiQuestionEditors = this.initJsonEditors(
      this.multiQuestionConfigJson,
      this.multiQuestionPromptJson,
      this.multiQuestionForm,
    );

    this.multiQuestionConfigJsonEditor = multiQuestionEditors.configJsonEditor;
    this.multiQuestionPromptJsonEditor = multiQuestionEditors.promptJsonEditor;

    const gptQuestionEditors = this.initJsonEditors(
      this.gptQuestionConfigJson,
      this.gptQuestionPromptJson,
      this.gptQuestionForm,
    );

    this.gptQuestionConfigJsonEditor = gptQuestionEditors.configJsonEditor;
    this.gptQuestionPromptJsonEditor = gptQuestionEditors.promptJsonEditor;
  }

  private extractConfigFromSnapshot(value: QuerySnapshot<any>) {
    const configJsonDoc = value.docs.find(
      (document) => document.id === 'configJson'
    ) as QueryDocumentSnapshot<{ value: typeof OPEN_AI_CONFIG }>;

    const promptJsonDoc =value.docs.find((document) => document.id === 'promptJson') as QueryDocumentSnapshot<{
      value: typeof PROMPT_CONFIG_DEFAULT
    }>;

    return {
      configJson: configJsonDoc ? configJsonDoc.data().value : null,
      promptJson: promptJsonDoc ? promptJsonDoc.data().value : null
    };
  }

  private initJsonEditors(
    configElement: ElementRef,
    promptElement: ElementRef,
    form: UntypedFormGroup,
): { configJsonEditor: JSONEditor; promptJsonEditor: JSONEditor } {
    const configJsonElement = configElement.nativeElement;
    const configJsonEditor = new JSONEditor(configJsonElement, {
      mode: 'form',
      onChangeJSON: (json) => form.controls.configJson.patchValue(json),
    });

    configJsonEditor.set(form.value.configJson);

    const promptJsonElement = promptElement.nativeElement;
    const promptJsonEditor = new JSONEditor(promptJsonElement, {
      mode: 'form',
      onChangeJSON: (json) => form.controls.promptJson.patchValue(json),
    });

    promptJsonEditor.set(form.value.promptJson);

    return {
      configJsonEditor,
      promptJsonEditor
    };
  }

  public async handleSingleQuestionConfigSubmit() {
    this.isSingleQuestionConfigFetching = true;

    const documentRef = doc(this.firestore, `single-question-configs/configJson`);

    await setDoc(documentRef, {
      value: this.singleQuestionForm.controls.configJson.value,
    });

    this.isSingleQuestionConfigFetching = false;
  }

  public async handleSingleQuestionPromptSubmit() {
    this.isSingleQuestionConfigFetching = true;

    const documentRef = doc(this.firestore, `single-question-configs/promptJson`);

    await setDoc(documentRef, {
      value: this.singleQuestionForm.controls.promptJson.value,
    });

    this.isSingleQuestionConfigFetching = false;
  }

  public setSingleQuestionConfig(config: typeof OPEN_AI_CONFIG) {
    this.singleQuestionForm.controls.configJson.patchValue(config);
    this.singleQuestionConfigJsonEditor.set(config);
  }

  public resetSingleQuestionConfig() {
    this.setSingleQuestionConfig(OPEN_AI_CONFIG);
  }

  public setSingleQuestionPrompt(config: typeof PROMPT_CONFIG_DEFAULT) {
    this.singleQuestionForm.controls.promptJson.patchValue(config);
    this.singleQuestionPromptJsonEditor.set(config);
  }

  public resetSingleQuestionPrompt() {
    this.setSingleQuestionPrompt(PROMPT_CONFIG_DEFAULT);
  }

  public async handleMultiQuestionConfigSubmit() {
    this.isMultiQuestionConfigFetching = true;

    const documentRef = doc(this.firestore, `multi-question-configs/configJson`);

    await setDoc(documentRef, {
      value: this.multiQuestionForm.controls.configJson.value,
    });

    this.isMultiQuestionConfigFetching = false;
  }

  public async handleMultiQuestionPromptSubmit() {
    this.isMultiQuestionConfigFetching = true;

    const documentRef = doc(this.firestore, `multi-question-configs/promptJson`);

    await setDoc(documentRef, {
      value: this.multiQuestionForm.controls.promptJson.value,
    });

    this.isMultiQuestionConfigFetching = false;
  }

  public setMultiQuestionConfig(config: typeof OPEN_AI_CONFIG) {
    this.multiQuestionForm.controls.configJson.patchValue(config);
    this.multiQuestionConfigJsonEditor.set(config);
  }

  public resetMultiQuestionConfig() {
    this.setMultiQuestionConfig(OPEN_AI_CONFIG);
  }

  public setMultiQuestionPrompt(config: typeof PROMPT_CONFIG_DEFAULT) {
    this.multiQuestionForm.controls.promptJson.patchValue(config);
    this.multiQuestionPromptJsonEditor.set(config);
  }

  public resetMultiQuestionPrompt() {
    this.setMultiQuestionPrompt(PROMPT_CONFIG_DEFAULT);
  }

  public async handleGptQuestionConfigSubmit() {
    this.isGptQuestionConfigFetching = true;

    const documentRef = doc(this.firestore, `gpt-question-configs/configJson`);

    await setDoc(documentRef, {
      value: this.gptQuestionForm.controls.configJson.value,
    });

    this.isGptQuestionConfigFetching = false;
  }

  public async handleGptQuestionPromptSubmit() {
    this.isGptQuestionConfigFetching = true;

    const documentRef = doc(this.firestore, `gpt-question-configs/promptJson`);

    await setDoc(documentRef, {
      value: this.gptQuestionForm.controls.promptJson.value,
    });

    this.isGptQuestionConfigFetching = false;
  }

  public setGptQuestionConfig(config: typeof OPEN_AI_CONFIG) {
    this.gptQuestionForm.controls.configJson.patchValue(config);
    this.gptQuestionConfigJsonEditor.set(config);
  }

  public resetGptQuestionConfig() {
    this.setGptQuestionConfig(OPEN_AI_CONFIG);
  }

  public setGptQuestionPrompt(config: typeof GPT_PROMPT_CONFIG_DEFAULT) {
    this.gptQuestionForm.controls.promptJson.patchValue(config);
    this.gptQuestionPromptJsonEditor.set(config);
  }

  public resetGptQuestionPrompt() {
    this.setGptQuestionPrompt(GPT_PROMPT_CONFIG_DEFAULT);
  }
}

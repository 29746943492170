<div class="content-container title">
  Login
</div>

<form
  class="content-container"
  [formGroup]="authForm"
  (ngSubmit)="handleAuthSubmit()"
>
  <div class="row row-start">
    <mat-form-field>
      <!--    <mat-label>Password</mat-label>-->
      <input
        matInput
        formControlName="email"
        type="email"
        placeholder="Email"
      />
      <mat-error *ngIf="authForm.controls.email.invalid">
        Invalid email
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <!--    <mat-label>Password</mat-label>-->
      <input
        matInput
        formControlName="password"
        type="password"
        placeholder="Password"
      />
      <mat-error *ngIf="authForm.controls.password.invalid">
        {{authForm.controls.password.getError('incorrect')}}
      </mat-error>
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!authForm.valid"
    >
      Submit
    </button>
  </div>
</form>

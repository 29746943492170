<div class="content-container">
  <div class="content-row title">
    Predicted questions
  </div>

  <div>
    <ng-container *ngIf="questionsByDocuments$ | async as questionsByDocuments">
      <mat-accordion class="questions-accordion">
        <ng-container *ngFor="let questionsByDocument of questionsByDocuments">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title class="mat-panel-title">
                {{questionsByDocument.fileName}}
              </mat-panel-title>
              <mat-panel-description>
                <button
                  *ngIf="currentDeletingFileId !== questionsByDocument.fileId"
                  mat-icon-button
                  (click)="handleQuestionsDelete(questionsByDocument)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>

                <mat-spinner
                  *ngIf="currentDeletingFileId === questionsByDocument.fileId"
                  diameter="15"
                ></mat-spinner>

              </mat-panel-description>
            </mat-expansion-panel-header>

            <div>
              <ng-container *ngFor="let predictedQuestion of questionsByDocument.questions">
                <div class="answer-content">
                  <div class="card-content content-row">
                    <div class="question-title text-bold">
                      <span class="text-bold">
                        Question:
                      </span>

                      {{predictedQuestion.question}}
                    </div>
                  </div>

                  <div class="card-content content-row">
                    <span class="text-bold">
                      Answer:
                    </span>

                    {{predictedQuestion.answer.message}}
                  </div>

                  <div class="card-content">
                    <ng-container *ngIf="predictedQuestion.answer.fileName">
                      <span class="text-bold">
                        File:
                      </span>

                      {{predictedQuestion.answer.fileName}}
                    </ng-container>

                    <ng-container *ngIf="!predictedQuestion.answer.fileName && predictedQuestion.answer.fileMetadata">
                      <div class="row articles-row">
                        <span class="text-bold">
                          Article:
                        </span>

                        <a
                          [routerLink]="['/articles', predictedQuestion.answer.fileMetadata.id]"
                          class="article-link"
                        >
                          {{predictedQuestion.answer.fileMetadata.name}}
                        </a>
                      </div>
                    </ng-container>
                  </div>

                  <div class="card-content">
                    <span class="text-bold">
                      Score:
                    </span>

                    {{predictedQuestion.answer.score}}
                  </div>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </ng-container>
  </div>
</div>

import {  Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private $appScrolledToBottomSubject: Subject<void> = new Subject();

  public get $appScrolledToBottom(): Observable<void> {
    return this.$appScrolledToBottomSubject.asObservable();
  }

  public callAppScrolledToBottom(): void {
    this.$appScrolledToBottomSubject.next();
  }
}

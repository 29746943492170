import { Component, inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Firestore, docData, doc } from '@angular/fire/firestore';

import { Question, QuestionType, Feedback, FeedbackSource } from 'models/question';

import { GenerativeQaScope } from 'app/pages/multi-question/multi-question.component.models';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, OnDestroy {
  private firestore: Firestore = inject(Firestore);

  public question$: Observable<Question>;
  public feedback$: Observable<Feedback>;

  public question: Question;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.setQuestion();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private getQuestionFeedbackTotalCount(question: Question, feedbackValue: boolean): number {
    if (!question.feedback) {
      return 0;
    }

    return question.feedback.reduce<number>((questionCounter, { isPositive }) => {
      if (isPositive === feedbackValue) {
        return questionCounter + 1;
      }

      return questionCounter;
    }, 0);
  }

  private setQuestion (): void {
    const { id } = this.route.snapshot.params;

    const questionDoc = doc(this.firestore, `questions/${id}`);

    // this.question$ = (docData(questionDoc) as Observable<Question>).pipe(
    //   map((question) => {
    //     return {
    //       // ...question,
    //       // dateCreated: (new Date(question.timestamp)).toLocaleString(),
    //       // typeString: this.getTypeString(question.type),
    //       // totalPositiveFeedback: this.getQuestionFeedbackTotalCount(question, true),
    //       // totalNegativeFeedback: this.getQuestionFeedbackTotalCount(question, false),
    //     };
    //   }),
    // );

    const subscription = (docData(questionDoc) as Observable<Question>).subscribe(
      (question) => {
        const totalPositiveFeedback = this.getFeedbackTotalCountBySource(question, true);
        const totalNegativeFeedback = this.getFeedbackTotalCountBySource(question, false);


        const responsesObject = question.responses ? {
          [GenerativeQaScope.Documents]: {
            ...question.responses[GenerativeQaScope.Documents],
            results: question.responses[GenerativeQaScope.Documents].results.filter((result) => result),
          },
          [GenerativeQaScope.Articles]: {
            ...question.responses[GenerativeQaScope.Articles],
            results: question.responses[GenerativeQaScope.Articles].results.filter((result) => result),
          },
          [GenerativeQaScope.Llm]: {
            ...question.responses[GenerativeQaScope.Llm],
            results: question.responses[GenerativeQaScope.Llm].results.filter((result) => result),
          },
        } : undefined;

        this.question = {
          ...question,
          responses: responsesObject,
          dateCreated: (new Date(question.timestamp)).toLocaleString(),
          typeString: this.getTypeString(question.type),
          totalPositiveFeedbackByArticles: totalPositiveFeedback.articles,
          totalPositiveFeedbackByFiles: totalPositiveFeedback.files,
          totalNegativeFeedbackByArticles: totalNegativeFeedback.articles,
          totalNegativeFeedbackByFiles: totalNegativeFeedback.files,
        };

        console.log(this.question);

        this.changeDetectorRef.detectChanges();
      },
    );

    this.subscriptions.push(subscription);
  }

  private getFeedbackTotalCountBySource(question: Question, feedbackValue: boolean): {
    articles: number;
    files: number;
  } {
    if (!question.feedback) {
      return {
        articles: 0,
        files: 0,
      };
    }

    const articlesFeedback = question.feedback.filter(({ source }) => source === FeedbackSource.Articles);

    const filesFeedback = question.feedback.filter(({ source }) => source === FeedbackSource.Files);

    const calculateFeedback = (feedback: Feedback[]): number => feedback.reduce<number>((feedbackCounter, { isPositive }) => {
      if (isPositive === feedbackValue) {
        return feedbackCounter + 1;
      }

      return feedbackCounter;
    }, 0);

    return {
      articles: calculateFeedback(articlesFeedback),
      files: calculateFeedback(filesFeedback),
    };
  }

  private getTypeString (type: QuestionType): string {
    switch(type) {
      case QuestionType.SingleQuestion:
        return 'Single question';

      case QuestionType.MultiQuestion:
        return 'Multi question';

      case QuestionType.PredictedQuestion:
        return 'Predicted question';

      default:
        return 'Unknown'
    }
  }
}

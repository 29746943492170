import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './shared/services/auth/auth.service';
import { User } from './shared/services/auth/auth.service.models';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public $isAuthorized: Observable<boolean>;
  public $user: Observable<User>;

  constructor(
    private authService: AuthService,
    private appService: AppService,
  ) {}

  ngOnInit(): void {
    this.$isAuthorized = this.authService.$isAuthorized;

    this.$user = this.authService.$user;

    const subscription = this.$isAuthorized.subscribe((isAuthorized) => {
      if (isAuthorized) {
        this.authService.fetchUser();
      }

      subscription.unsubscribe();
    });
  }

  public onScroll(event: Event): void {
    const target = event.target as HTMLDivElement;

    if (target.offsetHeight + target.scrollTop >= target.scrollHeight - 100) {
      this.appService.callAppScrolledToBottom();
    }
  }
}

<ng-container *ngIf="files$ | async as files">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="mat-panel-title">
          Files
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div>
        <ng-container *ngFor="let file of files">
          <div class="card-content row file-row">
            <span class="text-bold">
              {{file.name}}
            </span>

            <button
              *ngIf="!activeActionFileFullPath || activeActionFileFullPath !== file.fullPath"
              mat-mini-fab
              color="primary"
              class="action-container action-button"
              title="Generate"
              (click)="handleFileClick(file)"
            >
              <mat-icon
                 fontIcon="settings_backup_restore"
                 class="icon"
              ></mat-icon>
            </button>

            <mat-spinner
              *ngIf="activeActionFileFullPath === file.fullPath"
              diameter="35"
              class="action-container"
            ></mat-spinner>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TermsService } from 'app/pages/terms/terms.service';
import { Term } from 'app/pages/terms/terms.models';

@Component({
  selector: 'app-articles',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public createTermForm: UntypedFormGroup | null = null;
  public isFetching = false;

  public terms$: Observable<Term[]>;

  constructor(
    private termsService: TermsService,
  ) {}

  public ngOnInit(): void {
    this.createTermForm = new UntypedFormGroup({
      term: new UntypedFormControl('', [Validators.required]),
    });

    this.fetchTerms();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private fetchTerms(): void {
    this.isFetching = true;

    this.terms$ = this.termsService.fetchTerms().pipe(
      tap(() => {
        this.isFetching = false;
      })
    );
  }

  public addTerm(): void {
    this.isFetching = true;

    const subscription = this.termsService.addTerm(this.createTermForm.value).subscribe(() => {
      this.isFetching = false;
      this.fetchTerms();
    });

    this.subscriptions.push(subscription);
  }

  public deleteTerm(term: Term): void {
    this.isFetching = true;

    const subscription = this.termsService.deleteTerm(term.id).subscribe(() => {
      this.isFetching = false;
      this.fetchTerms();
    });

    this.subscriptions.push(subscription);
  }
}

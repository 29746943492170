<div
  class="content-container"
>
  <ng-container *ngIf="isFetching">
    <div class="overlay-container">
      <mat-spinner
        [diameter]="35"
      ></mat-spinner>
    </div>
  </ng-container>

  <div class="content-row row">
    <div class="title">
      Terms
    </div>
  </div>

  <div class="content-row row">
    <form
      [formGroup]="createTermForm"
      (ngSubmit)="addTerm()"
      class="row form"
    >
      <mat-form-field>
        <input
          matInput
          formControlName="term"
          placeholder="Create term"
        />
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!createTermForm.valid || isFetching"
      >
        Create
      </button>
    </form>
  </div>

  <div class="content-row subtitle">
    List:
  </div>

  <ng-container *ngFor="let term of terms$ | async">
    <div class="row">
      <div>
        {{term.term}} (id: {{term.id}})

        <ng-container *ngIf="term.syncedAt">
          <span class="text-bold">(synced)</span>
        </ng-container>
      </div>

      <button
        mat-icon-button
        (click)="deleteTerm(term)"
        [disabled]="isFetching"
      >
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </ng-container>
</div>

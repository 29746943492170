import { inject, Injectable } from '@angular/core';
import { Firestore, updateDoc, getDoc, doc } from '@angular/fire/firestore';

import { Feedback, Question } from 'models/question'

import { AuthService } from 'app/shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionsFeedbackService {
  private firestore: Firestore = inject(Firestore);

  private userId: string;

  constructor(
    private authService: AuthService,
  ) {
    this.authService.$uid.subscribe((uid) => {
      this.userId = uid;
    });
  }

  public async setFeedback(questionId: string, feedback: Feedback): Promise<void> {
    const documentRef = doc(this.firestore, 'questions', questionId);
    const document = await getDoc(documentRef);
    const documentData = document.data() as Question;

    const feedbackValue = {
      ...feedback,
      userId: this.userId,
    }

    let nextFeedback: Feedback[];

    if (!documentData?.feedback) {
      nextFeedback = [
        feedbackValue,
      ];

      await updateDoc(documentRef, 'feedback', nextFeedback);

      return;
    }

    const existingFeedbackIndex = documentData.feedback.findIndex(({ userId, source }) => userId === this.userId && source === feedback.source);

    if (existingFeedbackIndex === -1) {
      nextFeedback = [
        ...documentData.feedback,
        feedbackValue,
      ];
    } else {
      nextFeedback = [...documentData.feedback];

      nextFeedback[existingFeedbackIndex] = {
        ...documentData.feedback[existingFeedbackIndex],
        ...feedbackValue,
      };
    }

    await updateDoc(documentRef, 'feedback', nextFeedback);
  }
}

<div
  class="content-container"
>
  <ng-container
    *ngIf="(article$ | async) as article"
  >
    <div class="content-row row">
      <div class="title">{{article.article.title}}, PMID: {{article.PMID}}</div>

      <button
        *ngIf="!isGenerateQuestionsInProcess"
        mat-mini-fab
        color="primary"
        class="action-container action-button"
        title="Generate predicted questions"
        (click)="handleGenerateQuestionsClick(article)"
      >
        <mat-icon
          fontIcon="settings_backup_restore"
          class="icon"
        ></mat-icon>
      </button>
    </div>

    <div class="content-row">
      <div class="subtitle">
        Published date: {{article.dateCompleted}}
      </div>

      <div class="subtitle">
        Published by: {{getAuthorsString(article)}}
      </div>
    </div>

    <div class="content-row" [innerHtml]="article.article.body"></div>
  </ng-container>
</div>

<div
  class="content-container"
>
  <div class="content-row row">
    <div class="title">
      Questions ({{questionsTotalCount || 0}})
    </div>

    <div class="row question-metadata-row">
      <div class="subtitle">Positive: {{positiveFeedbackTotalCount || 0}}</div>
      <div class="subtitle">Negative: {{negativeFeedbackTotalCount || 0}}</div>
    </div>
  </div>

  <ng-container *ngIf="isFetching">
    <div class="overlay-container">
      <mat-spinner
        [diameter]="35"
      ></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngFor="let question of questions">
    <div class="content-row">
      <div class="row article-row">
        <a
          class="nav-link"
          [routerLink]="['/questions', question.id]"
        >
          {{question.data.text}}
        </a>
      </div>

      <div class="row question-metadata-row">
        <div>
          Date: {{question.data.dateCreated}}
        </div>

        <div>
          Type: {{question.data.typeString}}
        </div>
      </div>

      <div class="row question-metadata-row">
        <ng-container *ngIf="question.data.totalPositiveFeedbackByArticles || question.data.totalNegativeFeedbackByArticles">
          <span class="text-bold">
            PubMed
          </span>
        </ng-container>

        <ng-container *ngIf="question.data.totalPositiveFeedbackByArticles">
          <div class="row question-metadata-row">
            <mat-icon
              fontIcon="thumb_up"
              class="icon"
            ></mat-icon>
  
            <span>{{question.data.totalPositiveFeedbackByArticles}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="question.data.totalNegativeFeedbackByArticles">
          <div class="row question-metadata-row">
            <mat-icon
              fontIcon="thumb_down"
              class="icon"
            ></mat-icon>
  
            <span>{{question.data.totalNegativeFeedbackByArticles}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="question.data.totalPositiveFeedbackByFiles || question.data.totalNegativeFeedbackByFiles">
          <span class="text-bold">
            Basel
          </span>
        </ng-container>

        <ng-container *ngIf="question.data.totalPositiveFeedbackByFiles">
          <div class="row question-metadata-row">
            <mat-icon
              fontIcon="thumb_up"
              class="icon"
            ></mat-icon>
  
            <span>{{question.data.totalPositiveFeedbackByFiles}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="question.data.totalNegativeFeedbackByFiles">
          <div class="row question-metadata-row">
            <mat-icon
              fontIcon="thumb_down"
              class="icon"
            ></mat-icon>
  
            <span>{{question.data.totalNegativeFeedbackByFiles}}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

import { Component, OnInit, inject } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {
  PredefinedQuestionRequestPayload,
  PredefinedQuestionResponse,
} from './ask-predefined-question.component.models';

@Component({
  selector: 'app-ask-predefined-question',
  templateUrl: './ask-predefined-question.component.html',
  styleUrls: ['./ask-predefined-question.component.scss']
})
export class AskPredefinedQuestionComponent implements OnInit {
  private functions: Functions = inject(Functions);

  public isFetching = false;

  public response: PredefinedQuestionResponse | null = null;

  public messageForm: UntypedFormGroup | null = null;

  constructor() {}

  public ngOnInit(): void {
    this.messageForm = new UntypedFormGroup({
      message: new UntypedFormControl('', [Validators.required]),
    });
  }

  public async handleMessageSubmit(): Promise<void> {
    const payload: PredefinedQuestionRequestPayload = {
      message: this.messageForm.value.message,
    };

    const findInDocumentsFunction = httpsCallable<
      PredefinedQuestionRequestPayload,
      PredefinedQuestionResponse
      >(this.functions, 'findInPredefinedQuestions', { timeout: 540000 });

    this.isFetching = true;
    this.response = null;

    const { data } = await findInDocumentsFunction(payload);

    this.isFetching = false;
    this.response = data;
  }
}

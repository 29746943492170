import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { map, tap, take, finalize } from 'rxjs/operators';

import { Document } from './documents.component.models';
import { DocumentsService } from './documents.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsStorageService {
  public pagginatedFiles$: Observable<Document[]>;

  public get files$(): Observable<Document[]> {
    return this.filesSubj$.asObservable();
  }

  public get isFetching$(): Observable<boolean> {
    return this.isFetchingSubj$.asObservable();
  }

  private filesSubj$ = new BehaviorSubject<Document[]>([]);
  private pageSubj$ = new BehaviorSubject<number>(1);
  private querySubj$ = new BehaviorSubject<string | null>(null);
  private isFetchingSubj$ = new BehaviorSubject<boolean>(false);

  private maxResults = 50;

  constructor(
    private documentsService: DocumentsService,
  ) {
    this.pagginatedFiles$ = combineLatest([this.files$, this.pageSubj$, this.querySubj$]).pipe(
      map(([files, page, query]) => {
        const end = page * this.maxResults
        
        let filteredFiles = files;

        if (query) {
          filteredFiles = filteredFiles.filter((file) => file.name.toLowerCase().includes(query.toLowerCase()));
        }

        if (end >= filteredFiles.length) {
          return filteredFiles;
        }

        return filteredFiles.slice(0, end);
      }),
    );

    this.fetchDocuments();
  }

  public fetchDocuments(): void {
    this.isFetchingSubj$.next(true);

    this.documentsService.fetchDocuments().pipe(
      take(1),
      tap((documents) => {
        this.filesSubj$.next(documents);
      }),
      finalize(() => {
        this.isFetchingSubj$.next(false);
      }),
    ).subscribe();
  }

  public addDocument(data: FormData): Observable<Document> {
    this.isFetchingSubj$.next(true);

    return this.documentsService.addDocument(data).pipe(
      take(1),
      finalize(() => {
        this.isFetchingSubj$.next(false);

        this.fetchDocuments();
      }),
    );
  }

  public deleteDocument(id: number): Observable<Object> {
    return this.documentsService.deleteDocument(id).pipe(
      take(1),
      tap(() => {
        this.fetchDocuments();
      }),
    );
  }

  public loadNextPage(): void {
    this.pageSubj$.next(this.pageSubj$.value + 1);
  }

  public setSearchQuery(query: string | null): void {
    this.pageSubj$.next(1);
    this.querySubj$.next(query);
  }
}

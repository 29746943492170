import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription, throwError } from 'rxjs';
import { debounceTime, map, catchError } from 'rxjs/operators';

import { AppService } from 'app/app.service';

import { Document } from './documents.component.models';

import { DocumentsStorageService } from './documents-storage.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public files$: Observable<Document[]>;

  public totalCount$: Observable<number>;

  public uploadErrorMessage: string | null = null;

  private subscriptions: Subscription[] = [];

  public searchForm: UntypedFormGroup | null = null;
  public isSearchFormSubmitted = false;

  public filesDeleteInProgress: Record<string, boolean> = {};

  constructor(
    private appService: AppService,
    public documentsStorageService: DocumentsStorageService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.searchForm = new UntypedFormGroup({
      query: new UntypedFormControl('', []),
    });

    const appServiceSubscription = this.appService.$appScrolledToBottom.subscribe(() => {
      this.documentsStorageService.loadNextPage();
    });

    this.subscriptions.push(appServiceSubscription);

    const searchFormSubscription = this.searchForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(({ query }) => {
        this.documentsStorageService.setSearchQuery(query);
      });

    this.subscriptions.push(searchFormSubscription);

    this.files$ = this.documentsStorageService.pagginatedFiles$;

    this.totalCount$ = this.documentsStorageService.files$.pipe(
      map((files) => files.length),
    )
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public deleteDocument(document: Document): void {
    this.filesDeleteInProgress = {
      ...this.filesDeleteInProgress,
      [document.id]: true,
    };

    this.uploadErrorMessage = null;

    this.documentsStorageService.deleteDocument(document.id).subscribe();
  }

  public onFileSelected(event: Event & { target: HTMLInputElement & EventTarget; }): void {
    const formData = new FormData();

    Array.from(event.target.files).forEach((file) => {
      formData.append("files", file);
    });

    this.uploadErrorMessage = null;

    this.documentsStorageService.addDocument(formData).pipe(
      catchError((error) => {
        const errorMessage = (error.error || []).map((errorItem) => errorItem?.error).filter((errorItem) => Boolean(errorItem)).join('; ');

        this.uploadErrorMessage = errorMessage || 'Something bad happened; please try again later.';

        return throwError(() => new Error(this.uploadErrorMessage));
      }),
    ).subscribe();
  }
}

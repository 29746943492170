import { Component, inject, OnInit } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { Feedback } from 'models/question'
import { QuestionsFeedbackService } from 'app/shared/services/questions-feedback/questions-feedback.service';
import { LastAskedQuestionsService } from 'app/shared/services/last-asked-questions/last-asked-questions.service';

import {
  FindInDocumentsRequestPayload,
  FindInDocumentsRequestResponse,
} from './single-question.component.models';

@Component({
  selector: 'app-single-question',
  templateUrl: './single-question.component.html',
  styleUrls: ['./single-question.component.scss']
})
export class SingleQuestionComponent {
  private functions: Functions = inject(Functions);

  public feedbackValue: boolean | null;

  public isFetching = false;

  public response: FindInDocumentsRequestResponse | null = null;

  constructor(
    private questionsFeedbackService: QuestionsFeedbackService,
    public lastAskedQuestionsService: LastAskedQuestionsService,
  ) {}

  public async handleMessageSubmit(payload: FindInDocumentsRequestPayload): Promise<void> {
    const findInDocumentsFunction = httpsCallable<
      FindInDocumentsRequestPayload,
      FindInDocumentsRequestResponse
      >(this.functions, 'findInDocumentsSingleQuestion', { timeout: 540000 });

    this.isFetching = true;
    this.response = null;
    this.feedbackValue = null;

    this.lastAskedQuestionsService.setSingleQuestionResponse(null);

    const { data } = await findInDocumentsFunction(payload);

    this.isFetching = false;
    this.response = data;

    this.lastAskedQuestionsService.setSingleQuestionResponse({
      message: payload.message,
      response: this.response,
    });
  }

  public async handleFeedbackSubmit(feedback: Feedback): Promise<void> {
    this.feedbackValue = feedback.isPositive;

    this.questionsFeedbackService.setFeedback(this.response.questionId, feedback);
  }
}

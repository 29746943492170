import { Component, inject, OnInit } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Firestore, collection, getDocs } from '@angular/fire/firestore';
import { StorageReference } from 'firebase/storage';
import { QuerySnapshot } from 'firebase/firestore';

import { FilesService } from 'app/shared/services/files/files.service';

import { Keywords, DeleteKeywordsRequestPayload } from './generated-keywords.component.models';

@Component({
  selector: 'app-generated-keywords',
  templateUrl: './generated-keywords.component.html',
  styleUrls: ['./generated-keywords.component.scss']
})
export class GeneratedKeywordsComponent implements OnInit{
  private functions: Functions = inject(Functions);
  private firestore: Firestore = inject(Firestore);

  public keywords$: Observable<Keywords[]>;
  public files$: Observable<StorageReference[]>;
  public filesFilteredList$: Observable<StorageReference[]>;

  public currentGeneratingFileFullPath: string | null;

  public currentDeletingId: string | null;

  constructor(
    private filesService: FilesService,
  ) {}

  public ngOnInit(): void {
    this.setKeywords();
    this.setFilesList();
  }

  private setFilesList(): void {
    this.files$ = this.filesService.files$;

    this.filesFilteredList$ = this.files$.pipe(
      mergeMap((files) => {
        return this.keywords$.pipe(
          map((keywords) => {
            return files.filter((file) => {
              const isFileExists = Boolean(keywords.find(({ file: keywordsFile }) => keywordsFile.name === file.name));
              return !isFileExists;
            });
          })
        );
      })
    );
  }

  private setKeywords(): void {
    const collectionRef = collection(this.firestore, 'predefined-keywords');

    this.keywords$ = from(getDocs(collectionRef)).pipe(
      map((data: QuerySnapshot<Keywords>) => {
        return data.docs.reduce<Keywords[]>((result, keywordsSnapshot) => {
          const keywords = keywordsSnapshot.data();
          const keywordsId = keywordsSnapshot.id;

          result.push({
            ...keywords,
            id: keywordsId
          });

          return result;
        }, []);
      }),
    );
  }

  public async handleKeywordsDelete(keywords: Keywords): Promise<void> {
    const findInDocumentsFunction = httpsCallable<
      DeleteKeywordsRequestPayload,
      void
      >(this.functions, 'deletePredefinedKeywords');

    const payload = {
      id: keywords.id,
    };

    this.currentDeletingId = keywords.id;
    
    await findInDocumentsFunction(payload);

    this.currentDeletingId = null;

    this.setKeywords();
    this.setFilesList();
  }
}

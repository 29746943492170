import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { Functions, httpsCallable } from '@angular/fire/functions';

import {
  Article,
  Collective,
  ExtendedPerson,
} from 'models/article';
import { GeneratePredefinedQuestionsPayload } from 'app/pages/articles/articles.component.models';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  private functions: Functions = inject(Functions);

  private firestore: Firestore = inject(Firestore);

  public article$: Observable<Article>;
  public articleId: string;

  public isGenerateQuestionsInProcess = false;

  constructor(
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.article$ = this.route.params.pipe(
      mergeMap((params) => {
        const articleDoc = doc(this.firestore, `articles/${params.id}`);

        this.articleId = articleDoc.id;

        return (docData(articleDoc) as Observable<Article>).pipe(
          map((article) => {
            return {
              ...article,
              article: {
                ...article.article,
                body: this.getArticleBody(article.article.body),
              }
            };
          })
        );
      }),
    );
  }

  private getArticleBody(body: string): string {
    return body
      .replace(/&lt;/g,'<')
      .replace(/&gt;/g,'>')
      .replace(/title/g, 'h2')
      .replace(/sec/g, 'div')
      .trim();
  }

  public async handleGenerateQuestionsClick(article: Article): Promise<void> {
    const findInDocumentsFunction = httpsCallable<
      GeneratePredefinedQuestionsPayload,
      void
      >(this.functions, 'generateArticlePredefinedQuestions');

    const payload: GeneratePredefinedQuestionsPayload = {
      id: this.articleId,
      articleText: article.article.body,
      articleTitle: article.article.title,
      PMID: article.PMID,
    };

    this.isGenerateQuestionsInProcess = true;

    await findInDocumentsFunction(payload);

    this.isGenerateQuestionsInProcess = false;
  }

  public getAuthorsString(article: Article): string {
    return article.article.authors.map((author) => {
      if ((author as Collective).collectiveName) {
        return (author as Collective).collectiveName;
      }

      const typedAuthor = author as ExtendedPerson;

      return `${typedAuthor.foreName} ${typedAuthor.lastName}`;
    }).join(', ');
  }
}

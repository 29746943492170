import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Document } from './documents.component.models';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private headers: HttpHeaders;
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      Authorization: '2c0ceb0c70740d39fc294243fc427ca7ea47efbf',
      'Content-Type': 'application/json',
    });

    this.baseUrl = 'https://europe-central2-buildingcloud-ai.cloudfunctions.net/documentsGateway/documents';
    // this.baseUrl = 'http://127.0.0.1:5001/buildingcloud-ai/europe-central2/documentsGateway/documents';
  }

  public fetchDocuments() {
    return this.http.get<Document[]>(this.baseUrl, {
      headers: this.headers,
    });
  }

  public addDocument(payload: FormData) {
    const headers = this.headers.delete('Content-Type');

    return this.http.post<Document>(this.baseUrl, payload, {
      headers: headers,
    });
  }

  public deleteDocument(id: number) {
    return this.http.delete(`${this.baseUrl}/${id}`, {
      headers: this.headers,
    });
  }
}

import { FindInDocumentsRequestResponse } from 'app/pages/single-question/single-question.component.models';
import { GenerativeQaResponse, GenerativeQaScope } from 'app/pages/multi-question/multi-question.component.models';
import { PredefinedQuestionResponse } from 'app/pages/ask-predefined-question/ask-predefined-question.component.models';

export enum QuestionType {
  SingleQuestion = 'singleQuestion',
  MultiQuestion  = 'multiQuestion',
  PredictedQuestion  = 'predictedQuestion',
}

export enum FeedbackSource {
  Articles = 'articles',
  Files = 'files',
}

export interface Feedback {
  isPositive: boolean;
  source: FeedbackSource;
  userId?: string;
}

interface QuestionBase <Type extends QuestionType, Response> {
  userId: string;
  timestamp: number;
  text: string;
  type: Type;
  response?: Response;
  responses?: { [key in GenerativeQaScope]?: Response };
  error?: string;
  feedback?: Feedback[];
}

interface QuestionModel <Type extends QuestionType, Response> extends QuestionBase<Type, Response> {
  dateCreated?: string;
  typeString?: string;
  totalPositiveFeedbackByArticles?: number;
  totalPositiveFeedbackByFiles?: number;
  totalNegativeFeedbackByArticles?: number;
  totalNegativeFeedbackByFiles?: number;
}

export type SingleQuestion = QuestionModel<QuestionType.SingleQuestion, Omit<FindInDocumentsRequestResponse, 'questionId'>>;

export type MultiQuestion = QuestionModel<QuestionType.MultiQuestion, Omit<GenerativeQaResponse, 'questionId'>>;

export type PredictedQuestion = QuestionModel<QuestionType.PredictedQuestion, Omit<PredefinedQuestionResponse, 'questionId'>>;

export type Question = MultiQuestion;

<ng-template #navLinks>
  <div class="nav-links">
    <a
      routerLink="/"
      routerLinkActive="active-link"
      class="nav-link"
      [routerLinkActiveOptions]="{exact: true}"
    >
      Ask question
    </a>

    <a
      routerLink="/predicted-questions"
      routerLinkActive="active-link"
      class="nav-link"
      [routerLinkActiveOptions]="{exact: true}"
    >
      Predicted questions
    </a>

    <a
      routerLink="/ask-predefined-question"
      routerLinkActive="active-link"
      class="nav-link"
      [routerLinkActiveOptions]="{exact: true}"
    >
      Predicted questions flow
    </a>

    <a
      routerLink="/generated-keywords"
      routerLinkActive="active-link"
      class="nav-link"
      [routerLinkActiveOptions]="{exact: true}"
    >
      Keywords
    </a>

    <a
      routerLink="/questions"
      routerLinkActive="active-link"
      class="nav-link"
      [routerLinkActiveOptions]="{exact: true}"
    >
      History
    </a>

    <a
      routerLink="/articles"
      routerLinkActive="active-link"
      class="nav-link"
      [routerLinkActiveOptions]="{exact: true}"
    >
      Articles
    </a>

    <a
      routerLink="/terms"
      routerLinkActive="active-link"
      class="nav-link"
      [routerLinkActiveOptions]="{exact: true}"
    >
      Terms
    </a>

    <ng-container
      *ngIf="($user | async) as user"
    >
      <ng-container
        *ngIf="user && user.isAdmin"
      >

        <a
          routerLink="/documents"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Documents
        </a>

        <a
          routerLink="/config"
          routerLinkActive="active-link"
          class="nav-link"
          [routerLinkActiveOptions]="{exact: true}"
        >
          Admin config
        </a>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<mat-drawer-container class="drawer-container" [hasBackdrop]="true">
  <mat-drawer #drawer mode="over" class="drawer">
    <ng-container *ngTemplateOutlet="navLinks"></ng-container>
  </mat-drawer>

  <mat-drawer-content>
    <div class="content">
      <ng-container *ngIf="$isAuthorized | async">
        <div class="header">
          <div (click)="drawer.toggle()" class="header-mobile-nav">
            <mat-icon
              fontIcon="menu"
            ></mat-icon>
          </div>

          <div class="header-nav-links">
            <ng-container *ngTemplateOutlet="navLinks"></ng-container>
          </div>
        </div>
      </ng-container>
    
      <div
        class="content-inner"
        [ngClass]="{
          'content-inner-without-padding': !($isAuthorized | async)
        }"
        (scroll)="onScroll($event)"
      >
        <router-outlet></router-outlet>
      </div>
    
      <div class="footer">
        2013 - 2024, <span class="text-bold">Scharc Group - digital twins powered by AI</span>, info&#64;scharc.com
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>


import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Firestore, collection, query, limit, startAfter, getDocs, orderBy, getCountFromServer, where, QuerySnapshot } from '@angular/fire/firestore';
import { QueryDocumentSnapshot, Query, CollectionReference } from 'firebase/firestore';
import { Subscription } from 'rxjs';

import { Question, QuestionType, Feedback, FeedbackSource } from 'models/question';
import { AppService } from 'app/app.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit, OnDestroy {
  private firestore: Firestore = inject(Firestore);

  public questions: { id: string; data: Question }[] = [];

  private questionsCollection: CollectionReference;

  public questionsTotalCount: number;

  public positiveFeedbackTotalCount: number;
  public negativeFeedbackTotalCount: number;

  public isFetching = false;

  public cursor: QueryDocumentSnapshot<Question>;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.questionsCollection = collection(this.firestore, 'questions');
    
    this.fetchQuestions(
      query(
        this.questionsCollection,
        orderBy('timestamp', 'desc'),
      )
    );

    this.questionsTotalCount = (await getCountFromServer(this.questionsCollection)).data().count;

    const questions = await getDocs(this.questionsCollection);

    this.positiveFeedbackTotalCount = this.getFeedbackTotalCount(questions, true);
    this.negativeFeedbackTotalCount = this.getFeedbackTotalCount(questions, false);

    const appServiceSubscription = this.appService.$appScrolledToBottom.subscribe(() => {
      this.loadQuestions();
    });

    this.subscriptions.push(appServiceSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private getQuestionFeedbackTotalCount(question: Question, feedbackValue: boolean): number {
    if (!question.feedback) {
      return 0;
    }

    return question.feedback.reduce<number>((questionCounter, { isPositive }) => {
      if (isPositive === feedbackValue) {
        return questionCounter + 1;
      }

      return questionCounter;
    }, 0);
  }

  private getFeedbackTotalCountBySource(question: Question, feedbackValue: boolean): {
    articles: number;
    files: number;
  } {
    if (!question.feedback) {
      return {
        articles: 0,
        files: 0,
      };
    }

    const articlesFeedback = question.feedback.filter(({ source }) => source === FeedbackSource.Articles);

    const filesFeedback = question.feedback.filter(({ source }) => source === FeedbackSource.Files);

    const calculateFeedback = (feedback: Feedback[]): number => feedback.reduce<number>((feedbackCounter, { isPositive }) => {
      if (isPositive === feedbackValue) {
        return feedbackCounter + 1;
      }

      return feedbackCounter;
    }, 0);

    return {
      articles: calculateFeedback(articlesFeedback),
      files: calculateFeedback(filesFeedback),
    };
  }

  private getFeedbackTotalCount(snapshot: QuerySnapshot, feedbackValue: boolean): number {
    return snapshot.docs.reduce<number>((counter, document) => {
      const question = document.data();

      if (!question.feedback) {
        return counter;
      }

      const totalValuesCount = this.getQuestionFeedbackTotalCount(question as Question, feedbackValue);

      return counter + totalValuesCount;
    }, 0);
  }

  private loadQuestions(): void {
    if (!this.cursor) {
      return;
    }

    const queryFn: Query = query(
      this.questionsCollection,
      orderBy('timestamp', 'desc'),
      startAfter(this.cursor),
    );

    this.fetchQuestions(queryFn);
  }

  private async fetchQuestions(queryValue: Query): Promise<void> {
    this.isFetching = true;

    const queryWithLimit = query(
      queryValue,
      limit(20),
    );

    const { docs } = await getDocs(queryWithLimit);

    const questions = docs.map(doc => {
      const question = doc.data() as Question;

      const totalPositiveFeedback = this.getFeedbackTotalCountBySource(question, true);
      const totalNegativeFeedback = this.getFeedbackTotalCountBySource(question, false);

      return {
        id: doc.id,
        data: {
          ...question,
          dateCreated: (new Date(question.timestamp)).toLocaleString(),
          typeString: this.getTypeString(question.type),
          totalPositiveFeedbackByArticles: totalPositiveFeedback.articles,
          totalPositiveFeedbackByFiles: totalPositiveFeedback.files,
          totalNegativeFeedbackByArticles: totalNegativeFeedback.articles,
          totalNegativeFeedbackByFiles: totalNegativeFeedback.files,
        },
      };
    });

    if (docs.length) {
      this.cursor = docs[docs.length - 1] as QueryDocumentSnapshot<Question>;
    }

    this.questions = [...this.questions, ...questions];

    this.isFetching = false;
  }

  private getTypeString (type: QuestionType): string {
    switch(type) {
      case QuestionType.SingleQuestion:
        return 'Single question';

      case QuestionType.MultiQuestion:
        return 'Multi question';

      case QuestionType.PredictedQuestion:
        return 'Predicted question';

      default:
        return 'Unknown'
    }
  }
}
